<template>
  <!-- <div>个人信息</div> -->
  <el-container class="PersonalInformation">
    <el-header>个人信息</el-header>
    <el-main>
      <el-form label-width="80px"
        style="margin-top: 30px; width: 380px; height: 500px"
        label-position="right"
        :rules="rules"
        :model="ruleForm"
        ref="ruleForm">
        <el-form-item label="名称"
          prop="name">
          <el-input v-model="ruleForm.name"
            placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="头像"
          style="position: relative"
          prop="imageUrl">
          <span style="font-size: 12px; color: #7b7b7b; margin-left: 7px">
            <i class="el-icon-info"></i>
            &nbsp;仅支持PNG, JPG，文件小于1M，规格为181*150px
          </span>
          <el-upload action="#"
            :show-file-list="false"
            :on-change="imageChange"
            style="margin-left: 15px">
            <!-- <span v-if="ruleForm.imageUrl" class="headSculpture">
              <img :src="ruleForm.imageUrl" :class="imgSize" />
            </span>
            <i v-else class="el-icon-picture headSculpture Iborder"></i> -->

            <span class="headSculpture">
              <img :src="ruleForm.imageUrl"
                :class="imgSize" />
            </span>

          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%"
              :src="ruleForm.imageUrl" />
          </el-dialog>
        </el-form-item>
        <el-form-item label="手机号码"
          prop="phone">
          <el-input v-model="ruleForm.phone"
            minlength="3"
            maxlength="20"
            placeholder="请输入号码"
            @change="FormChange"
            @keyup.native="
              ruleForm.phone = ruleForm.phone.replace(/[^0-9]/g, '')
            "></el-input>
        </el-form-item>
        <el-form-item label="邮箱"
          prop="email">
          <el-input v-model="ruleForm.email"
            minlength="3"
            maxlength="64"
            placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="地址"
          prop="address">
          <el-input v-model="ruleForm.address"
            placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
            style="width: 80px"
            @click="onSaveData">确定</el-button>
          <el-button style="width: 80px; margin-left: 20px"
            @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>

<script>
//这里引入 查询个人信息 接口
import { getUserInfo, updateImageInfo } from "@/api/All-Interface.js";
// 信息传入vuex
import { mapState, mapActions } from "vuex";

const defaultAvator = require('@/assets/images/avatar.png');

export default {
  // `name`与路由保持一致
  name: "PersonalInformation",
  data() {
    return {
      //图片上传
      imagePath: "", //图片路径
      imgSize: "imgWidth", //图片的class
      dialogVisible: false, //放大icon
      ruleForm: {
        name: "",
        phone: "",
        email: "",
        address: "",
        imageUrl: '', // "",
      },
      //from表单验证
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        phone: [
          {
            required: true,
            message: "请输入正确的手机号码",
            trigger: "blur",
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          },
        ],
        email: [
          {
            required: true,
            message: "请输入正确邮箱地址",
            trigger: "blur",
            // pattern: /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
          },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        imageUrl: [{ required: false, message: "请选择图片", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    //点击确定，修改个人信息
    async onSaveData() {
      //先进行判断是否有填入信息
      if (!(await this.verDataInfo())) return;
      //接口要formData数据
      const mydata = new FormData();
      if (this.imagePath !== "") mydata.append("file", this.imagePath);
      mydata.append("customerName", this.ruleForm.name);
      mydata.append("phone", this.ruleForm.phone);
      mydata.append("email", this.ruleForm.email);
      mydata.append("address", this.ruleForm.address);
      mydata.append("userId", this.$store.state.userInfo.userId);

      try {
        const result = await updateImageInfo(mydata);

        this.getUserInfo(); //重新调用个人信息，以查看是否修改成功

        this.$message.success(result.msg);
        //提交成功则修改vuex 的手机号
        if (result.flag == 1) this.userInfo.phone = this.ruleForm.phone;
      } catch (error) {
        this.$message.error("查询异常");
        console.log(error);
      }
    },
    //点击取消
    resetForm(formName) {
      this.getUserInfo();
    },
    //针对输入手机号时通过鼠标粘贴中文
    FormChange(value) {
      value = value.replace(/[^0-9]/g, "");
      this.ruleForm.phone = value;
    },
    //验证个人信息输入框 input 必填是否已经填上
    verDataInfo() {
      const { ruleForm } = this.$refs;

      if (!ruleForm) return Promise.resolve(false);

      return new Promise((resolve, reject) => {
        ruleForm.validate((valid) => {
          resolve(valid);
        });
      });

    },
    //图片上传 改变图片时
    imageChange(file, fileList) {
      const ImgType =
        file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const ImgBat = file.size / 1024 / 1024 < 1;
      if (!ImgType) {
        this.$message.error("上传头像图片只能是jpg  png 格式!");
        return;
      }
      if (!ImgBat) {
        this.$message.error("上传头像图片大小不能超过 1MB!");
        return;
      }
      this.ruleForm.imageUrl = URL.createObjectURL(file.raw); //转换成文件流路径
      this.imagePath = file.raw;
      this.imgSizeConfig(this.ruleForm.imageUrl); //进行图片 class 的调整
    },
    imgSizeConfig(url) {
      //直接传入图片所需的url，用于调整图片的class
      //创建一个图片对象，然后通过这个图片对象的宽高值进行对比，谁长则取谁为 100% 通过变量控制 class从而实现
      let img = new Image();
      img.src = url;
      img.onload = () => {
        if (img.height > img.width) {
          this.imgSize = "imgHeight";
        } else {
          this.imgSize = "imgWidth";
        }
      };
    },
    async getUserInfo() {
      //进入和修改时调用个人信息
      this.ruleForm.imageUrl = '';
      try {
        const result = await getUserInfo({
          userId: this.$store.state.userInfo.userId,
        });

        this.$nextTick();
        const { obj } = result;
        this.ruleForm.name = obj.user.customerName;
        this.ruleForm.imageUrl = obj.user.picture || defaultAvator;
        this.imgSizeConfig(obj.user.picture); //进行显示图片的class
        this.ruleForm.phone = obj.user.phone;
        this.ruleForm.email = obj.user.email;
        this.ruleForm.address = obj.user.address;
      } catch (error) {
        this.$message.warning("查询异常");
        console.log(error);
      }
    },
  },
  mounted() {
    this.getUserInfo();
  },
};
</script>

<style lang="scss" scoped>
@import 'src/style/reportStyle.scss';
.el-form-item {
  margin-bottom: 7%;
  .el-input {
    width: 260px;
  }
}
//element 的图片上传的class
.Iborder {
  border: 1px solid #d9d9d9;
  // border-radius: 50%;
  cursor: pointer;
}
.headSculpture {
  font-size: 35px;
  color: #8c939d;
  width: 181px;
  height: 181px;
  line-height: 181px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  position: relative;
  // border-radius: 50%;
}
.imgHeight {
  height: 100%;
}
.imgWidth {
  width: 100%;
}
.headSculpture:hover {
  .ImageIcon {
    display: inline-block;
  }
}
//element 的图片上传的class [end]

.PersonalInformation {
  width: 44%;
  height: 80vh;
  margin: 5vh 26%;
  .el-header {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid;
    line-height: 60px;
    color: #1890ff;
    font-size: 16px;
    font-weight: bold;
  }
  .el-main {
    display: flex;
    justify-content: center;
  }
}
::v-deep .el-upload-list__item.is-ready {
  display: none;
}
</style>